import styled from "styled-components";
import Link from "../Link";
import { isMobile } from "react-device-detect";
import {
  MdWork,
  MdContactMail,
  MdPhotoLibrary,
  MdHomeFilled,
} from "react-icons/md";

const HeaderContainer = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  border-bottom: 1px solid #ccc;
  background-color: var(--background-color);
  color: var(--text-color);
`;
const HeaderSpace = styled.span`
  width: 100%;
  height: 60px;
  display: block;
`;
const HeaderLinks = styled(Link)<{ isMobile?: boolean }>`
  display: block;
  width: ${({ isMobile = false }) => (isMobile ? "50px" : "100px")};
  height: 60px;
  float: right;
  line-height: 60px;
  text-align: center;
`;
const HeaderIcon = styled(Link)`
  display: block;
  height: 60px;
  float: left;
  line-height: 60px;
  text-align: center;
  font-size: 30px;
  margin-left: 20px;
`;

interface Props {}
const Header: React.FC = (props: Props): React.ReactElement => {
  const items = [
    { to: "/works", icon: <MdWork />, text: "開発" },
    { to: "/photo", icon: <MdPhotoLibrary />, text: "写真" },
    { to: "/contact", icon: <MdContactMail />, text: "連絡" },
  ];
  return (
    <>
      <HeaderContainer>
        <HeaderIcon to="/">
          {isMobile ? <MdHomeFilled /> : "りょうこ"}
        </HeaderIcon>
        {items.map(({ to, icon, text }, index) => (
          <HeaderLinks isMobile={isMobile} to={to}>
            {isMobile ? icon : text}
          </HeaderLinks>
        ))}
      </HeaderContainer>
      <HeaderSpace />
    </>
  );
};

export default Header;
