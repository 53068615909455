import Template from "@templates";
import { Contact } from "@components";

const ContactPage: React.FC = () => (
  <Template>
    <Contact />
  </Template>
);

export default ContactPage;
