import styled from "styled-components";
import Link from "../Link";

const WorkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px;
`;
const WorkItemContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 20px;
`;
const WorkItemTitle = styled.div`
  font-size: 20px;
`;
const WorkItemDescription = styled.div`
  font-size: 12px;
`;

interface WorkItemProps {
  title: string;
  description: string;
  to: string;
}
const WorkItem: React.FC<WorkItemProps> = (props): React.ReactElement => {
  const { title, description, to } = props;
  return (
    <WorkItemContainer to={{ pathname: to }} target="_blank">
      <WorkItemTitle>{title}</WorkItemTitle>
      <WorkItemDescription>{description}</WorkItemDescription>
    </WorkItemContainer>
  );
};

interface Props {}
const Work: React.FC = (props: Props): React.ReactElement => {
  const works = [
    {
      title: "ox カレンダー",
      description: "◯とか×とかをつけて予定を確認することができます。",
      to: "https://calendar.りょうこ.com",
    },
  ];
  return (
    <WorkContainer>
      {works.map(({ title, description, to }) => (
        <WorkItem title={title} description={description} to={to} />
      ))}
    </WorkContainer>
  );
};

export default Work;
