import styled from "styled-components";
import main from "@images/main.jpg";

const Image = styled.img`
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
`;

const ImageSpace = styled.span`
  display: block;
  padding-bottom: calc(1080 / 1616 * 100%);
  width: 100%;
`;

const ImageText = styled.div`
  position: absolute;
  z-index: 20;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 4vw;
  padding: 10px;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.7);
  text-transform: uppercase;
  border-radius: 10px;
`;

interface Props {}
const Header: React.FC = (props: Props): React.ReactElement => {
  return (
    <ImageContainer>
      <ImageSpace />
      <Image src={main} />
      <ImageText>ryoko ando</ImageText>
    </ImageContainer>
  );
};

export default Header;
