import Template from "@templates";
import { Work } from "@components";

const WorksPage: React.FC = () => (
  <Template>
    <Work />
  </Template>
);

export default WorksPage;
