import styled from "styled-components";
import { Header, Footer } from "@components";

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
  color: var(--text-color);
`;

interface Props {
  children: React.ReactNode;
}
const Template: React.FC<Props> = (props: Props): React.ReactElement => {
  const { children } = props;
  return (
    <Container>
      <Header />
      {children}
      <Footer />
    </Container>
  );
};

export default Template;
