import { createClient } from "microcms-js-sdk"; //ES6

const API_KEY = process.env.REACT_APP_MICRO_CMS_API_KEY;
const DOMAIN = process.env.REACT_APP_MICRO_CMS_API_URL;

if (!DOMAIN || !API_KEY)
  throw new Error("Please set env: MICRO_CMS_API_KEY, MICRO_CMS_API_URL");

const client = createClient({
  serviceDomain: DOMAIN, // YOUR_DOMAIN is the XXXX part of XXXX.microcms.io
  apiKey: API_KEY,
  // retry: true // Retry attempts up to a maximum of two times.
});

export default client;
