import Template from "@templates";
import { Mainphoto, About } from "@components";

const HomePage: React.FC = () => (
  <Template>
    <Mainphoto />
    <About />
  </Template>
);

export default HomePage;
