import React, { useEffect, useState } from "react";
import styled from "styled-components";
import cms from "@libs/microCMS";
import Link from "@components/Link";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 33%;
  gap: 20px;
  margin: 20px 0 0 20px;
  flex-wrap: wrap;
`;
const ItemContainer = styled(Link)`
  display: flex;
  flex-direction: column;

  flex-basis: calc(33% - 22px);
  border: 1px solid #ccc;
  border-radius: 10px;
`;
const ImageContainer = styled.div`
  width: 100%;
  position: relative;
  &:before {
    content: "";
    display: block;
    padding-bottom: calc(1080 / 1616 * 100%);
  }
`;
const Image = styled.img`
  border-radius: 10px 10px 0 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const Text = styled.div`
  font-size: 12px;
  width: calc(100% - 20px);
  height: 70px;
  padding: 10px;
  white-space: pre-wrap;
  word-break: break-word;
  word-wrap: break-word;
  overflow-y: scroll;
`;

interface ItemProps {
  src: string;
  text: string;
  id: string;
}
const Item: React.FC<ItemProps> = (props): React.ReactElement => {
  const { src, text, id } = props;
  return (
    <ItemContainer to={`/photo/${id}`}>
      <ImageContainer>
        <Image src={src} />
      </ImageContainer>

      <Text>{text}</Text>
    </ItemContainer>
  );
};

interface Blog {
  contents: {
    id: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    revisedAt: string;
    content: string;
    title: string;
    eyecatch: {
      url: string;
      width: number;
      height: number;
    };
  }[];
  limit: number;
  offset: number;
  totalCount: number;
}

interface Props {}
const Photo: React.FC = (props: Props): React.ReactElement => {
  const [imgs, setImgs] = useState<Blog>();

  const getImages = async () => {
    const res = await cms.get({ endpoint: "blogs" });
    setImgs(res);
    console.log(res);
  };
  useEffect(() => {
    getImages();
  }, []);

  return (
    <Container>
      {imgs?.contents?.map((content) => (
        <Item src={content.eyecatch.url} text={content.title} id={content.id} />
      ))}
    </Container>
  );
};

export default Photo;
