import styled from "styled-components";

const AboutContainer = styled.div`
  display: flex;
  color: var(--text-color);
  background-color: inherit;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;
`;
const Label = styled.div`
  text-align: center;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px;
`;
const Name = styled.div`
  text-align: center;
  font-size: 22px;
`;
const Title = styled.div`
  text-align: center;
  font-size: 10px;
  margin-bottom: 20px;
`;
const Text = styled.div`
  text-align: center;
  font-size: 16px;
  white-space: pre-wrap;
  word-break: break-all;
`;

interface Props {}
const About: React.FC = (props: Props): React.ReactElement => {
  return (
    <AboutContainer>
      <Label>About</Label>
      <Name>りょうこ</Name>
      <Title>女装男子 & developper</Title>
      <Text>
        1996年生まれ。女装してイベントスタッフしたり、ポトレ撮ったり。{"\n"}
        有償無償問わずポートレートを募集しております。
      </Text>
    </AboutContainer>
  );
};

export default About;
