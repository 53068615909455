import React, { useEffect, useState } from "react";
import styled from "styled-components";
import cms from "@libs/microCMS";
import Link from "@components/Link";
import { useParams } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  flex-wrap: wrap;
`;
const Image = styled.div<{ src?: string }>`
  width: 100%;
  position: relative;
  padding-bottom: calc(1080 / 1616 * 100%);

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(${(props) => props.src});
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
  }
`;

const Header = styled.header`
  font-size: 20px;
  font-weight: bold;
`;
const Content = styled.div``;
const BackButton = styled(Link)`
  width: 100px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 10px;
  background-color: var(--primary-color);
  color: var(--primary-text-color);
`;

interface Blog {
  id: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  revisedAt: string;
  content: string;
  title: string;
  eyecatch: {
    url: string;
    width: number;
    height: number;
  };
}

interface Props {}
const Photo: React.FC = (props: Props): React.ReactElement => {
  const { id } = useParams<{ id: string }>();

  if (!id) throw new Error("idがありません");

  const [blog, setBlog] = useState<Blog>();

  const getImages = async () => {
    const res = await cms.get({ endpoint: "blogs", contentId: id });
    setBlog(res);
    console.log(res);
  };
  useEffect(() => {
    getImages();
  }, [id]);

  return (
    <Container>
      <Image src={blog?.eyecatch?.url} />
      <Header>{blog?.title}</Header>
      <Content dangerouslySetInnerHTML={{ __html: blog?.content || "" }} />
      <BackButton to="/photo">戻る</BackButton>
    </Container>
  );
};

export default Photo;
