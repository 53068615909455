import Template from "@templates";
import { Photos } from "@components";

const PhotoPage: React.FC = () => (
  <Template>
    <Photos />
  </Template>
);

export default PhotoPage;
