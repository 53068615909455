import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Home, Works, Photos, Contact, Notfound, Photo } from "./pages";

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/works" exact component={Works} />
      <Route path="/contact" exact component={Contact} />
      <Route path="/photo" exact component={Photos} />
      <Route path="/photo/:id" exact component={Photo} />
      <Route path="*" exact component={Notfound} />
    </Switch>
  </BrowserRouter>
);

export default App;
