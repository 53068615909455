import styled from "styled-components";

const FooterContainer = styled.div`
  z-index: 100;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  border-top: 1px solid #ccc;
  background-color: inherit;
  color: var(--text-color);
  font-size: 20px;
  line-height: 60px;
  text-align: center;
`;
const FooterSpace = styled.span`
  width: 100%;
  height: 60px;
  display: block;
`;

interface Props {}
const Footer: React.FC = (props: Props): React.ReactElement => {
  return (
    <>
      <FooterContainer>©️ryokoando</FooterContainer>
      <FooterSpace />
    </>
  );
};

export default Footer;
