import styled from "styled-components";
import { AiFillTwitterCircle, AiFillInstagram } from "react-icons/ai";
import Link from "../Link";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  color: var(--text-color);
  background-color: inherit;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 20px;
`;
const Item = styled(Link)`
  display: flex;
  flex-basis: 50%;
  color: var(--text-color);
  background-color: inherit;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
  border-radius: 10px;
  border: 1px solid #ccc;
`;
const Icon = styled.div`
  display: flex;
  flex-basis: 100px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 10rem;
`;
const Text = styled.div`
  display: flex;
  flex-basis: 100px;
  color: var(--text-color);
  background-color: inherit;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 20px;
  white-space: pre-wrap;
  word-break: break-all;
`;

interface Props {}
const Contact: React.FC = (props: Props): React.ReactElement => {
  const contacts = [
    {
      icon: <AiFillTwitterCircle />,
      text: "@ryokoandoにご連絡ください。\ntwitterのDMが返信が早いと思います。",
      to: "https://twitter.com/ryokoando",
    },
    {
      icon: <AiFillInstagram />,
      text: "@ryokoando1にご連絡ください。\n週１〜月１程度で更新中",
      to: "https://www.instagram.com/ryokoando1/",
    },
  ];
  return (
    <Container>
      {contacts.map(({ icon, text, to }) => (
        <Item to={{ pathname: to }} target="_blank">
          <Icon>{icon}</Icon>
          <Text>{text}</Text>
        </Item>
      ))}
    </Container>
  );
};

export default Contact;
